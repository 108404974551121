import React from 'react';

import Contact from '@myungsoo/base/pages/admin/Contact';

import AdminLayout from '../../components/AdminLayout';
import Seo from '../../components/Seo';

import * as config from '../../../config';

export default () => (
  <AdminLayout>
    <Seo title="Admin" />
    <Contact id="contact" config={config} />
  </AdminLayout>
);
